<template>
  <div>
      <div class="row justify-content-center mt-5">
            <!-- <h3 class="text-center">{{title}}</h3> -->
            <div class=" col-md-10 text-center mt-5">
                <div class="input-group row" >
                  <div class="col-md-3 col-sm-12">
                    <b-icon icon="bar-chart-fill" class="iconoInput"></b-icon>
                    <input type="date" v-model="row.birthday" class="form-control inputPadding" placeholder="FECHA DE NACIMIENTO" style= "height:70px;" >
                  
                  </div>
                  <div class="col-md-6">
                    <input v-model="row.name" type="text" class="form-control ml-1" placeholder="NOMBRE Y APELLIDO"  style= "height:70px;">
                  </div>
                  <div class="col-md-3">
                    <input v-model="row.appointment" type="text" class="form-control" placeholder="CARGO"  style= "height:70px;">
                  </div>
                </div>
                
                <div class="input-group row mt-5">
                  <div class="col-md-3">
                    <input v-model="row.email" type="email" class="form-control" placeholder="EMAIL"  style= "height:70px;">
                  </div>
                  <div class="col-md-3">   
                    <input v-model="row.phone" type="text" class="form-control" placeholder="TELEFONO"  style= "height:70px;">
                  </div> 
                  <div class="col-md-3">
                    <input v-model="row.picture" type="text" class="form-control" placeholder="NOMBRE FOTO"  style= "height:70px;">
                      <!-- <label class="btn btn-secondary btn-block pointer" style="height:70px !important;">
                        <span style="margin-top:5px !important">SUBIR FOTO</span>
                        <input type="file" style="display: none;" accept="image/*" @change="onFileSelected($event)" />                    
                          &nbsp;<i class="fa fa-upload"></i>
                      </label> -->

                      <!-- <button class="btn btn-secondary btn-block " type="button" style="height: 70px;" >SUBIR FOTO 
                          &nbsp;<i class="fa fa-upload"></i>
                      </button> -->
                      <!-- <input
                        class="btn"
                        type="file"
                        accept=".*"
                        @change="onFileSelected($event)"
                        name="myfile"
                      /> -->
                  </div>

                  <div class="col-md-3" >
                      <button @click.prevent="saveData()" class="btn btn-danger btn-block " type="button" style="height: 70px;">GUARDAR </button>
                  </div>
                    
                  <div class="col-md-3 mt-3">
                      <input :unchecked-value="'0'" type="checkbox" name="status" :value="'1'" v-model="row.status" id="status"> Activo
                  </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
export default {
  props:['user', 'title'],
  data(){
    return{
      row:{
        id: 0,
        birthday: null,
        name: null,
        appointment: null,
        email: null,
        phone: null,
        picture: '/users/',
        status: '1',
        role: 2,

      }
    }
  },
  mounted(){
    console.log('in mounted', this.user)
    if (this.user){
      this.row = this.user
    }
  },
  methods:{
    async addNew(fileUrl, quizId) {
      try {
        let url = this.url + "studentAnswers/";
        let opts = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({url: fileUrl, quizId: quizId, studentId: this.user.id}),
        };

        let req = await fetch(url, opts);
        if (req.ok) {
          // this.$refs["modal-1"].hide();
        }
        // this.getData();
      } catch (error) {
        console.log(error);
      }
    },       
    async onFileSelected(event) {
      try {
        const file = event.target.files[0];
        let formData = new FormData();
        formData.append("name", "my-picture");
        formData.append("file", file);

        let filename = file.name;
        filename = filename.toLowerCase();
        console.log('filename ', filename)
        let url = this.$store.state.url  + "uploadFile/" + filename + '/image';
        let opts = {
          method: "POST",
          body: formData,
        };
        let req = await fetch(url, opts);
         if (req.ok) { // omitido temporalmente
          //if (req) {
          this.row.picture = "https://platino.2blweb.com/users/" + filename 
          //this.addNew("https://admin.mwlanguage.com/exams/" + filename, this.quizId );
          this.$swal.fire({
            title: "Imagen subida correctamente, no olvides guardar cambios.",
            icon: "info",
          });
          await this.getData();
          //   this.$refs["modal-1"].hide();
        }
      } catch (error) {
        console.log("error", error);
      }
    },   
     async saveData(){
      try {
        let methodRequest
        let msg
        let path
        this.row.status = this.row.status == true ? 1 : 0
        if(this.row.id>0){
          console.log('cumple ',this.row.birthday)
          methodRequest= 'put'
          let fechac = this.row.birthday.substr(0,4) + '/' + this.row.birthday.substr(5,2) + '/' + this.row.birthday.substr(8,2)
          this.row.birthday = fechac
          console.log('fechac', fechac)

          delete(this.row.age)
          path = 'user/' + this.row.id
          msg = 'Datos actualizados correctamente.'
        } 
        else{
          methodRequest= 'post'
          delete(this.row.id)
          delete(this.row.age)
          this.row.picture = '/users/' + this.row.picture
          path = 'user'
          msg = 'Datos ingresados correctamente.'
        }
        let options = {
          method: methodRequest,
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(this.row)
        }
        let req = await fetch(this.$store.state.url + path, options)
          if (req.ok) {
            alert(msg)
            this.$store.commit('updateUserList', true);
          }
      }
      catch(err){
        console.log(err)

      }
    }
  }
}
</script>

<style>
  .iconoInput {
    color: #000000;
    font-size: 15px;
    position: absolute;
    left: 25px;
    padding-top: 10px;
    }
  .inputPadding {
    padding-left: 40px;
    }

</style>